module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"sm":0,"md":769,"lg":1025,"xl":1281},"interactions":{"hover":"(hover: hover)","notHover":"(hover: none)","landscape":"not all and (orientation: landscape)","portrait":"not all and (orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-114974500-1","4279570470","G-1Q1CREHB00"],"gtagConfig":{"optimize_id":"GTM-MF6B3M6","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"exclude":["/tags/","/blog/"]}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
